body {
    margin: 0;
    font-family: "PT Sans Narrow", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #a53f40;
}

/* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
} */
