body {
    background: #a53f40;
}

h1 {
    text-align: center;
    font-size: 75px;
    font-family: "Sacramento", cursive;
}

/* main {
    max-width: 930px;
    margin: 4rem auto;
    margin-top: -8rem;
} */

.albums {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
}

.albums img {
    width: 100%;
}

section img {
    box-shadow: 8px 8px 5px 1px rgba(17, 17, 17, 0.438);
}

@media (min-width: 700px) {
    .albums {
        grid-gap: 28px;
    }
}

@media (max-width: 700px) {
    .albums {
        padding: 3.75%;
        display: grid;
        grid-template-columns: 100%;
        grid-column-gap: 0;
        grid-row-gap: 20px;
    }

    h1 {
        margin-top: 100px;
    }
}

.container {
    position: relative;
    margin: 2rem;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: #008cba;
}

.container:hover .overlay {
    opacity: 1;
}

.text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
